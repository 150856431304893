import Button from '@/components/Button';
import { ButtonVariant } from '@/components/ButtonVariant';
import type { CmsAsset, CmsLink } from '@/components/CmsInterfaces';
import { Icon, IconVariant } from '@/components/Icon';
import Image from '@/components/Image';
import { TeaserTileHeader } from '@/components/TeaserTileHeader';
import { DateFormat, formatDate } from '@/utilities/dateUtils';
import classNames from 'classnames';
import React from 'react';

export interface TeaserTileProps {
  title: string;
  text?: string;
  date: string | number;
  image?: CmsAsset | string;
  link: CmsLink | string;
  category: string;
  cms?: boolean;
  buttonLabel?: string;
}

export class TeaserTile extends React.Component<TeaserTileProps> {
  static defaultProps: Partial<TeaserTileProps> = {
    image: null,
    cms: true,
  };

  private get buttonLabel(): string {
    return this.props.buttonLabel || 'Mehr erfahren';
  }

  private get ariaLabel(): string {
    return 'Mehr erfahren zu ' + this.props.title;
  }

  private get link(): string {
    const url = this.props.link;
    if (typeof url === 'string') {
      return url;
    } else {
      return url ? url.path || '/' : '/';
    }
  }

  private get image(): string | null {
    let imageSource = this.props.image;
    if (typeof imageSource !== 'string') {
      imageSource = imageSource ? imageSource.path : null;
    }
    if (typeof imageSource === 'string') {
      return imageSource.includes('://')
        ? imageSource
        : `/assets/images${imageSource}`;
    }
    return null;
  }

  render(): JSX.Element {
    const image = this.image;
    const { title, text, date, category } = this.props;
    return (
      <div
        title={title}
        className="TeaserTile"
        tabIndex={-1}
        onClick={() => (window.location.href = this.link)}
      >
        <div
          className={classNames('TeaserTile__frame', {
            TeaserTile__fallback: image === null,
          })}
        >
          {image !== null ? (
            <Image src={image} alt={title} width={600} />
          ) : (
            <TeaserTileHeader category={category} />
          )}
        </div>
        <div className="TeaserTile__content">
          <small>{formatDate(date, DateFormat.LongMonth)}</small>
          <strong>{title}</strong>
          {text && <p>{text}</p>}
          <Button variant={ButtonVariant.Text} ariaLabel={this.ariaLabel}>
            <span>{this.buttonLabel}</span>
            <Icon variant={IconVariant.ArrowRight} />
          </Button>
        </div>
      </div>
    );
  }
}
